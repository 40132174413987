import Axios from "./AxiosInstance.js";

export const getInfoBlock = async (from, to, token) => {
  // const token = await getTokenAndSetToken();
  if(from !== undefined || to!==undefined)  {
    return Axios.get(
      `https://nivid.nivid.app/studentdata?from_date=${from}&to_date=${to}&token=${token}`
    );
  }
  else {
    return Axios.get(
      `https://nivid.nivid.app/studentdata?from_date=2022-02-01&to_date=2022-03-05&token=${token}`
    );
  }
};


export const getUsageCondition = async(token) => {
  // const token = await getTokenAndSetToken();
  return Axios.get(
    `https://nivid.nivid.app/user_piechart?from_date=2022-02-01&to_date=2022-03-05&token=${token}&tenant=vidhyasagar&from_date=2021-02-01&to_date=2022-05-03       `
  );
};

export const getTokenAndSetToken = async () => {
  if (sessionStorage.getItem("token") == null) {
    console.log("tokenSes", sessionStorage.getItem("token"));
    await Axios.get("https://nivid.nivid.app/generate_token").then((res) => {
      if (res.status == 200) {
        console.log("token", res);
        let token = prompt();
        sessionStorage.setItem("token", token);
        return sessionStorage.getItem("token");
      }
    });
  } else return sessionStorage.getItem("token");

  //  call api and return new Promise((resolve, reject) => {
};
