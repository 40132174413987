import React, { useState, useEffect, useMemo } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ApexChart from "../Piechart/Piechart";
import "./LineGraph.scss";
import ReactApexChart from "react-apexcharts";
import { getInfoBlock } from "../../Api/GetApi";
import { DatePicker } from "../DatePicker/DatePicker";
export const LineGraph = ({ token }) => {
  const [details, setDetails] = useState();
  const [sumData, setSumData] = useState([]);
  const [dayData, setDayData] = useState([]);
  const [fromdate, setFromdate] = useState();
  const [todate, setTodate] = useState();
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    getInfoBlock(fromdate, todate, token).then((res) => {
      setDetails(res.data);
    });
  }, [token]);

  useEffect(() => {
    details?.data?.map((item) => {
      sumData.push(parseInt(item.sum));
      dayData.push(item.day);
    });
  }, [details]);
  
  useEffect(()=>{
    setSeries([
      {
        name: "series1",
        data: sumData,
      },
    ]);
    setOptions({
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: dayData,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    });
  }, [sumData, dayData])

  
  return (
    <div className="linegraph-section">
      <div className="custom-card mid-section">
        <div className="graph-details">
          <div className="graph-topic">
            <p className="title-content">Weekly Profile Growth(in %)</p>

            <DateRangePicker
              name="daterange"
              block
              onChange={(date) => {
                console.log(date);
                if (date !== undefined) {
                  const fromDate = new Date(date[0]);
                  const toDate = new Date(date[1]);
                  const formattedFromDate =
                    fromDate.getFullYear() +
                    "-" +
                    (fromDate.getMonth() + 1) +
                    "-" +
                    fromDate.getDate();
                  const formattedToDate =
                    toDate.getFullYear() +
                    "-" +
                    (toDate.getMonth() + 1) +
                    "-" +
                    toDate.getDate();
                  getInfoBlock(
                    formattedFromDate,
                    formattedToDate,
                  ).then((res) => {
                    setDetails(res.data);
                  });
                }
              }}
            />
          </div>

          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={245}
            width={700}
          />
        </div>
      </div>
    </div>
  );
};
export default LineGraph;
