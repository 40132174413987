import axios from "axios";
export const domain = "https://api.axiossoftwork.com";
// export const imgdomain = "https://storage.googleapis.com/axios-documents/";

const instance = axios.create({
  baseURL: domain + "/api/",
  headers: {
    "content-type": "application/json",
  },
});

instance.interceptors.request.use(
  (req) => {
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default instance;
