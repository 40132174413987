import React from "react";
import "./BlockingUser.scss";
const BlockingUser = () => {
  return (
    <div className="block-user-section">
      <div className="custom-card mid-section">
        <div className="title-content">Blocking User</div>

        <div class=" search-box">
          <input
            type="text"
            class="form-control input-text"
            aria-label="Recipient's username"
            placeholder="Search Here"
            aria-describedby="basic-addon2"
          />
          <div className="image-eyeglass">
            <img src={require("../../images/eyeglass.png")} alt="Vector" />
          </div>
        </div>

        <div className="wrapper">
          <div className="school-content">
            <div className="school-image">
              <img src={require("../../images/vidyasagar.png")} alt="Vector" />
            </div>
            <div className="school-details">
              <div className="school-name">
                <h5 className="medium-title">Vidya Sagar Eng. Sec. school</h5>
                <div className="close-right-icon">
                  <img src={require("../../images/close.png")} alt="Vector" />
                </div>
              </div>
              <p className="content">Banepa, Kavre</p>
              <button className="btn-second">Unblock Domain</button>
            </div>
          </div>

          <div className="school-content">
            <div className="school-image">
              <img src={require("../../images/orchid.png")} alt="Vector" />
            </div>
            <div className="school-details">
              <div className="school-name">
                <h5 className="medium-title">Vidya Sagar Eng. Sec. school</h5>
                <div className="close-right-icon">
                  <img src={require("../../images/right.png")} alt="Vector" />
                </div>
              </div>
              <p className="content">Banepa, Kavre</p>

              <button className="primary-btn">Block Domain</button>
            </div>
          </div>

          <div className="school-content">
            <div className="school-image">
              <img src={require("../../images/axios.png")} alt="Vector" />
            </div>
            <div className="school-details">
              <div className="school-name">
                <h5 className="medium-title">Vidya Sagar Eng. Sec. school</h5>
                <div className="close-right-icon">
                  <img src={require("../../images/right.png")} alt="Vector" />
                </div>
              </div>
              <p className="content">Banepa, Kavre</p>

              <button className="primary-btn">Block Domain</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlockingUser;
