import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getUsageCondition } from "../../Api/GetApi";
import "./Piechart.scss";

export const Piechart = ({token}) => {
  const [usage, setUsage] = useState();

  const [count, setCount] = useState([]);
  const [events, setEvents] = useState([]);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    getUsageCondition(token).then((res) => {
      debugger
      setUsage(res.data);
    });
  }, [token]);

  useEffect(() => {
    
    usage?.data?.map((item) => {
      
      count.push(item.count);
      events.push(item.events);
    });
  }, [usage]);

  useEffect(()=>{
    setSeries(count);
    setOptions({
      dataLabels: {
        enabled: false,
      },
      chart: {
        width: 380,
        type: "donut",
      },
      legend: {
        position: "bottom",
      },
      markers: {
        shape: "rectangle",

        radius: 19,
      },

      style: {
        fontSize: "12px",
        font: "Poppins",
        weight: 500,
      },
      colors: [
        "#EB5587",
        "#57DF75",
        "#3EBDF7",
        "#E7C54E",
        "#8B69C8",
        "#E26029",
      ],
      labels: events,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },

            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });
  }, [count, events])

  const [state, setState] = useState({
    series: count,
    options: {
      dataLabels: {
        enabled: false,
      },
      chart: {
        width: 380,
        type: "donut",
      },
      legend: {
        position: "bottom",
      },
      markers: {
        shape: "rectangle",

        radius: 19,
      },

      style: {
        fontSize: "12px",
        font: "Poppins",
        weight: 500,
      },
      colors: [
        "#EB5587",
        "#57DF75",
        "#3EBDF7",
        "#E7C54E",
        "#8B69C8",
        "#E26029",
      ],
      labels: events,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },

            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  return (
    <div className="chart-topic">
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          width={400}
        />
      </div>
    </div>
  );
};
export default Piechart;
